<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <custom-loading
      :loading="loading_pedidos"
      color="greyTxt"
      opacity=".4"
      z-index="99"
    />
    <div
      class="ml-2 font-weight-medium text-h5 black--text pt-4 mb-4 d-flex align-center"
    >
      Pedidos
    </div>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-card
          elevation="0"
          color="transparent"
        >
          <!-- Tabla de Pedidos -->
          <generic-table
            :loading="loading_pedidos"
            :headers="headers_pedidos"
            :items="pedidos"
            height="unset"
            :sort-by="['id_pedido']"
            :options="{
              'itemsPerPage':20
            }"
            :data-table="dataTablePedidos"
            show-select
            :actions="actions_pedidos"
            item-key="idemcmc"
            @openRemitos="openRemitos"
            @openDetalles="openDetalles"
            @changeSelected="changeSelected"
            @search="handleSearch"
            @getReporte="getReporte"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get, call, sync } from 'vuex-pathify'
  import xlsController from '@/xls/download'
  import { getDestinos } from '@/routes/options'
  import { genericRequest } from '@/api/modules'

  export default {
    name: 'DashboardView',

    data () {
      return {
        destinos: [],
        loadingDestinos: false,

        idemcmcArray: [],
        actions_pedidos: [
          {
            icon: 'view-grid-outline',
            title: 'Remitos',
            color: 'sendBtn',
            emit: 'openRemitos',
            attr: { dark: true },
          },
          {
            icon: 'eye',
            title: 'Detalle',
            color: 'primary',
            emit: 'openDetalles',
          },
        ],

      }
    },

    computed: {
      pedidos: get('pedidos/items'),
      headers_pedidos: get('pedidos/headersCliente'),
      loading_pedidos: get('pedidos/loading'),
      count_pedidos: get('pedidos/count'),
      user: get('user/clienteInterno'),
      cliente_id () {
        return this.$route.params?.cliente_id
      },
      id_destino () {
        return this.$route.params?.id_destino
      },
      alert: sync('app/alert'),
      alertTxt: sync('app/alertTxt'),
      dataTablePedidos () {
        const dataTablePedidos = {
          search: [
            {
              param: 'id_pedido',
              placeholder: 'N° de pedido',
              cols: 2,
            },
            {
              param: 'idemcmc',
              placeholder: 'Buscar por orden de entrega',
              cols: 3,
            },
            {
              param: 'suministro',
              placeholder: 'Suministro',
              cols: 2,
            },
          ],
          filters: [
            {
              cols: 5,
              fields: [
                {
                  cols: 12,
                  param: 'id_destino',
                  placeholder: 'Unidad destino',
                  type: 'v-select',
                  attr: { 'deletable-chips': true, items: this.destinos, multiple: true, chips: true },
                },
              ],

            },
            {
              title: 'Fecha de entrega',
              cols: 3,
              fields: [
                {
                  cols: 6,
                  param: 'desde',
                  placeholder: 'Desde',
                  type: 'DatePicker',
                },
                {
                  cols: 6,
                  param: 'hasta',
                  placeholder: 'Hasta',
                  type: 'DatePicker',
                },
              ],

            },

          ],
          actionsBtn: [
            {
              title: 'Generar reporte',
              action: 'getReporte',
              cols: 12,
              color: 'sendBtn',
            },

          ],
        }
        return dataTablePedidos
      },
    },
    created () {
      this.initialize()
      this.getOptions()
    },
    methods: {
      getPedidos: call('pedidos/getDataCliente'),
      getReportePedidos: call('pedidos/reporteClientes'),
      async getOptions () {
        this.loadingDestinos = true
        const { resources } = await genericRequest({
          url: getDestinos,
          method: 'get',
        })
        this.destinos = resources.map(e => ({ id: e.id_destino, text: `${e.id_destino}` }))
        this.loadingDestinos = false
      },
      handleSearch (query) {
        this.initialize(query)
      },
      initialize (query = {}) {
        this.getPedidos({ id_cliente: this.cliente_id, id_destino: this.id_destino, ...query })
      },
      openRemitos (send) {
        this.$router.push(`/remitos/${send.idemcmc}/${send.id_pedido}/${this.user.name}?id_proveed=${send.id_proveed}`)
      },
      openDetalles (details) {
        this.$router.push(`/pedido/${details.idemcmc}/${this.user.name}/${details.id_pedido}?id_proveed=${details.id_proveed}`)
      },
      changeSelected (data) {
        this.idemcmcArray = data
      },
      async getReporte () {
        if (this.idemcmcArray?.length) {
          const { items, headers, widths } = await this.getReportePedidos({ idemcmcArray: this.idemcmcArray })
          xlsController.downloadXls(
            {
              items,
              headers,
              widths,
              fileName: 'Reporte de pedidos',
              sheetName: 'Articulos por pedidos',
              fileSubject: 'Pedidos',
            },
          )
        } else {
          this.alertTxt = {
            timeout: 3700,
            type: 'error',
            txt: 'Debe seleccionar algún ítem para generar el reporte',
          }
          this.alert = true
        }
      },
    },
  }
</script>
